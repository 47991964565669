<template>
  <div class="px-6 py-8">
    <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
      <div class="mb-4 flex items-center justify-between">
        <div>
          <h3 class="text-xl font-bold text-gray-900 mb-2">Users</h3>
          <span class="text-base font-normal text-gray-500">View and manage users.</span>
        </div>
        <div class="flex-shrink-0">
          <button 
            @click="show"
            class='font-medium text-white bg-purple-600 btn-sm hover:bg-purple-500 group' 
          >
            <plus-icon 
              size="44" stroke-width="2"
              class="w-5 h-5 mr-3 group-hover:animate-horizontal-bounce" 
            /> Add user
          </button>
            <modal name="post-modal">
              Will be using this to add a new post
            </modal>
        </div>
      </div>
      
      <!-- Data table -->
      <div class="flex flex-col mt-8">
        <div class="overflow-x-auto rounded-lg">
          <div class="align-middle inline-block min-w-full">
            <div class="shadow overflow-hidden sm:rounded-lg">
              <table class="min-w-full divide-y divide-purple-100">
                <thead class="bg-purple-25">
                  <tr>
                    <th scope="col" class="p-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider w-0.5">
                      ID
                    </th>
                    <!-- <th scope="col" class="">&nbsp;</th> -->
                    <th colspan="2" scope="col" class="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                      User
                    </th>
                    <th scope="col" class="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Full Name
                    </th>
                    <th scope="col" class="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Role
                    </th>
                    <th scope="col" class="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th scope="col" class="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white">
                  <!-- User List -->
                  <user-list
                    v-for="(user, idx) in users" :key="user.id"
                    v-bind="{ user: user, index: idx }"
                  />

                    <!-- 
                    <select @change="changeRole(user.id, $event)">
                      <option :selected="user.role.driver" value="driver">Driver</option>
                      <option :selected="user.role.customer" value="customer">Customer</option>
                    </select> 
                    -->
                  

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase-app'
import UserList from '@/components/backend/UserList'
import { PlusIcon } from "vue-tabler-icons";

export default {
  name: 'UserView',
  components: {
    UserList,
    PlusIcon
  },
  methods: {
    /* Show modal */
    show() {
      this.$modal.show('post-modal')
    },
    /* Hide modal */
    hide() {
      this.$modal.hide('post-modal')
    }
  },
  created() {
    this.users = firebaseApp.getAllUSers()
  },
  data() {
    return {
      users: [],
    };
  },
}
</script>