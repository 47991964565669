/**
 * Methods and other logic related to Logging
 */
 export default {
  methods: {
    /* Logs event details */
    log(...args) {
      console.log(...args);
    }
  }
}