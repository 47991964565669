<template>
  <tr class="border-b border-purple-50 border-dashed">
    <td class="px-4 py-3 text-ms">
      <div class="flex flex-inline">
        <input 
          readonly
          ref="clone"
          v-on:select="$event.target.select()" 
          :value="user.id"
          style="width:0.1px"
        />
        <button 
          @click="copyToClipboard"
          alt="Copy to clipboard"
        >
          <copy-icon 
            v-tippy content="Copy to clipboard"
            size="44" stroke-width="1.5"
            class="h-5 w-5 text-purple-700 hover:text-purple-600" 
          />
        </button>
      </div>
    </td>
    <td class="pl-4 pr-1 py-3 w-14">
      <img class="h-8 w-8 rounded-full border" src="https://demo.themesberg.com/windster/images/users/neil-sims.png" alt="Neil image">
    </td>
    <td class="pl-1 pr-4 py-3">
      <div class="flex items-center text-sm">
        <div>
          <p class="font-semibold text-purple-700">{{ user.displayName }}</p>
          <p class="text-xs text-gray-600">{{ user.email }}</p>
        </div>
      </div>
    </td>
    <td class="px-4 py-3 text-ms">
      <div>
        <quick-edit 
          v-model="fullname"
          @input="saveFullname"
          :showButtons="false" 
        ></quick-edit>
        <!-- <p class="text-xs text-purple-700">Xxx</p> -->
        <!-- <p class="text-xs text-gray-600">by James Doe</p> -->
      </div>
    </td>
    <td class="px-4 py-3 text-ms">
      <div>
        <quick-edit 
          v-model="role"
          type="select"
          :options="roles"
          :placeholderValue="printCurrentRole()"
          :showButtons="false" 
          @input="saveRole"
          @close="log('close', $event)"
          @show="log('show', $event)"
        ></quick-edit>

        <!-- <p class="text-sm text-purple-700">{{ printCurrentRole() }}</p> -->
        <!-- <p class="text-xs text-gray-600">xxx</p> -->

      </div>
    </td>
    <td class="px-4 py-3 text-xs">
      <quick-edit 
        v-model="status"
        type="select"
        mode="ignore"
        :options="statuses"
        :showButtons="false" 
        @input="saveStatus"
        @close="log('close', $event)"
        @show="log('show', $event)"
      ></quick-edit>
      <!-- <span class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm">Active</span> -->

      <!-- <span class="px-2 py-1 font-semibold leading-tight text-orange-700 bg-gray-100 rounded-sm"> Pending </span> -->
      <!-- <span class="px-2 py-1 font-semibold leading-tight text-red-700 bg-red-100 rounded-sm"> Nnacceptable </span> -->
    </td>
    <td class="px-4 py-3 text-sm">
      <span class="flex">
        <!-- Edit -->
        <a href="#">
          <edit-icon 
            size="44" stroke-width="1.5"
            class="h-5 w-5 text-green-700 hover:text-green-500 mx-2" 
          />
        </a>
        <!-- Delete -->
        <a 
          href="#"
          v-on:click="deleteRow(index)"
        >
          <trash-icon 
            size="44" stroke-width="1.5"
            class="h-5 w-5 text-red-700 hover:text-red-500" 
          />
        </a>
      </span>
    </td>
  </tr>
</template>

<script>
// import {firebaseFunc} from '@/firebase-app';
import QuickEdit from 'vue-quick-edit';
import LogMixin from '@/mixins/Log'
import { TrashIcon, CopyIcon, EditIcon } from "vue-tabler-icons";

import firebaseApp from '@/firebase-app';
import { getFunctions } from "firebase/functions";
const firebaseFunc = getFunctions(firebaseApp)

import { httpsCallable } from 'firebase/functions';

export default {
  name: "UserListComponent",
  components: {
    QuickEdit,
    TrashIcon,
    CopyIcon,
    EditIcon
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  methods: {
    printCurrentRole() {
      let roleKeys = Object.keys(this.user.role)
      return roleKeys[0]
    },
    deleteRow(index) {
      this.$confirm(
        {
          message: `Are you sure you want to delete this record? Clicking "Yes" will 🔥 delete the record.`,
          button: {
            no: 'Cancel',
            yes: 'Yes'
          },
          /**
          * Callback Function
          * @param {Boolean} confirm 
          */
          callback: confirm => {
            if (confirm) {
              alert('Index ' + index + ' will be deleted.')
            }
          }
        }
      )
    },
    /**
     * Update and save the newly selected fullname
     */
    saveFullname() {
      console.log('fullname:', this.fullname)
    },
    /**
     * Update and save the newly selected role
     */
    async saveRole() {
      var data = { 
        uid: this.user.id, 
        role: { [this.role.toLowerCase()]: true } 
      };

      console.log('Data', data)

      // const updateUserRoleFunc = firebaseFunc.httpsCallable("updateUserRole");
      const updateUserRoleFunc = httpsCallable(firebaseFunc, 'updateUserRole');      
    
      await updateUserRoleFunc(data)
        .then(function(result) {
          console.log(result.data);
        })
        .catch(function(err) {
          console.log(err)
        });
    },
    /**
     * Update and save the newly selected status
     */
    async saveStatus() {
      console.log('Selected status:', this.status)

      // const callHelloFunc = httpsCallable(firebaseFunc, 'callHello'); 
      // await callHelloFunc({ name: 'Shams' })
      //   .then(result => {
      //     console.log('saveStatus', result.data)
      //   })
    },


    copyToClipboard() {
      this.$refs.clone.select();
      document.execCommand('copy');
      this.$toast.open('User Id copied to clipboard.');
    },
  },

  beforeUpdate() {
    console.log('Initializing User role.')
    this.role = this.user.role
  },
  data() {
    return {
      fullname: '',
      status: '',   // selected status
      role: '',
      statuses: [
        { value: '1', text: 'Active' },
        { value: '2', text: 'Pending' }
      ],
      roles: [ 'Admin', 'Teacher', 'Student' ]
    }
  },
  mixins: [ LogMixin ]
}
</script>